var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"dayspan"}},[_c('ds-calendar-app',{ref:"app",attrs:{"calendar":_vm.calendar,"read-only":_vm.readOnly},on:{"change":_vm.saveState},scopedSlots:_vm._u([{key:"eventPopover",fn:function(slotData){return [_c('ds-calendar-event-popover',_vm._b({attrs:{"read-only":_vm.readOnly},on:{"finish":_vm.saveState}},'ds-calendar-event-popover',slotData,false))]}},{key:"eventCreatePopover",fn:function(ref){
var placeholder = ref.placeholder;
var calendar = ref.calendar;
var close = ref.close;
return [_c('ds-calendar-event-create-popover',{attrs:{"calendar-event":placeholder,"calendar":calendar,"close":_vm.$refs.app.$refs.calendar.clearPlaceholder},on:{"create-edit":_vm.$refs.app.editPlaceholder,"create-popover-closed":_vm.saveState}})]}},{key:"eventTimeTitle",fn:function(ref){
var calendarEvent = ref.calendarEvent;
var details = ref.details;
return [_c('div',[(details.icon)?_c('v-icon',{staticClass:"ds-ev-icon",style:({color: details.forecolor}),attrs:{"size":"14"}},[_vm._v(" "+_vm._s(details.icon)+" ")]):_vm._e(),_c('strong',{staticClass:"ds-ev-title"},[_vm._v(_vm._s(details.title))])],1),_c('div',{staticClass:"ds-ev-description"},[_vm._v(_vm._s(_vm.getCalendarTime(calendarEvent)))])]}}])},[_c('template',{slot:"drawerBottom"},[_c('div',{staticClass:"pa-3"},[_c('v-checkbox',{attrs:{"label":"Read Only?"},model:{value:(_vm.readOnly),callback:function ($$v) {_vm.readOnly=$$v},expression:"readOnly"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }